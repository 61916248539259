<!-- eslint-disable vue/no-template-target-blank -->
<template>
    <div class="main-wrapper">
        <div class="slide-menu">
            <div class="slide-menu__wrapper js-slide-menu__wrapper">
                <nav class="slide-menu slide-menu--slide-left">
                    <div class="slide-menu__header">
                        <a
                            href="/"
                            class="slide-menu__logo-link"
                            title="CareWave"
                        >
                            <img
                                src="@/assets/logo.png"
                                alt=""
                                class="logo"
                            >
                        </a>
                    </div>
                    <div class="slide-menu__body">
                        <ul class="menu-mobile">
                            <li class="menu-mobile__item">
                                <router-link
                                    :to="{name: $options.ROUTE.ECAREWAVE_INDEX, hash: '#how'}"
                                    class="menu-mobile__link"
                                >
                                    {{ $t('pages.home.menu.howItWorks') }}
                                </router-link>
                            </li>
                            <li class="menu-mobile__item">
                                <router-link
                                    :to="{name: $options.ROUTE.ECAREWAVE_INDEX, hash: '#contact'}"
                                    class="menu-mobile__link"
                                >
                                    {{ $t('pages.home.menu.contact') }}
                                </router-link>
                            </li>
                            <li class="menu-mobile__item">
                                <router-link
                                    :to="{name: $options.ROUTE.MAP}"
                                    class="menu-mobile__link"
                                >
                                    {{ $t('pages.home.menu.map') }}
                                </router-link>
                            </li>
                            <li class="menu-mobile__item">
                                <router-link
                                    :to="{name: $options.ROUTE.ECAREWAVE_ABOUT}"
                                    class="menu-mobile__link"
                                >
                                    {{ $t('pages.home.menu.about') }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <!--
                    <div class="slide-menu__footer">
                        <a
                            href="https://www.facebook.com/CarewaveIdeasInstitute"
                            target="_blank"
                        >
                            <span class="ecare-facebook"></span>
                        </a>
                        <a
                            href="https://twitter.com/icarewave"
                            target="_blank"
                        >
                            <span class="ecare-twitter"></span>
                        </a>
                    </div>
                    -->
                </nav>
            </div>
            <div class="slide-menu__mask js-slide-menu__mask"></div>
        </div>

        <div class="sections">
            <header class="header">
                <div class="header__logo">
                    <img
                        src="@/assets/logo.png"
                        alt=""
                        class="logo"
                    >
                </div>
                <div class="header__menu">
                    <ul class="menu-primary">
                        <li class="menu-primary__item">
                            <div class="menu-primary__link d-inline-flex">
                                <menu-lang-switcher
                                    :languages="$options.LANGUAGES"
                                ></menu-lang-switcher>
                            </div>
                            <router-link
                                :to="{name: $options.ROUTE.MAP}"
                                class="menu-primary__link"
                            >
                                {{ $t('pages.home.menu.map') }}
                            </router-link>
                            <router-link
                                :to="{name: $options.ROUTE.ECAREWAVE_ABOUT}"
                                class="menu-primary__link"
                            >
                                {{ $t('pages.home.menu.about') }}
                            </router-link>
                            <router-link
                                :to="{name: $options.ROUTE.ECAREWAVE_INDEX, hash: '#how'}"
                                class="menu-primary__link"
                            >
                                {{ $t('pages.home.menu.howItWorks') }}
                            </router-link>
                            <router-link
                                :to="{name: $options.ROUTE.ECAREWAVE_INDEX, hash: '#contact'}"
                                class="menu-primary__link"
                            >
                                {{ $t('pages.home.menu.contact') }}
                            </router-link>
                        </li>
                    </ul>
                </div>
                <!--
                <div class="header__actions sr sr-reveal">
                    <a
                        href="https://www.facebook.com/CarewaveIdeasInstitute"
                        target="_blank"
                        class="menu-primary__link menu-primary__icon"
                    >
                        <span class="ecare-facebook"></span>
                    </a>
                    <a
                        href="https://twitter.com/icarewave"
                        target="_blank"
                        class="menu-primary__link menu-primary__icon"
                    >
                        <span class="ecare-twitter"></span>
                    </a>
                </div>
                -->
                <div class="header__lang-switcher">
                    <menu-lang-switcher
                        :languages="$options.LANGUAGES"
                    ></menu-lang-switcher>
                </div>
                <div class="header__mobile">
                    <a
                        href
                        class="menu-icon js-slide-menu"
                    >
                        <div class="menu-icon__content">
                            <div class="menu-icon__line menu-icon__line--1"></div>
                            <div class="menu-icon__line menu-icon__line--2"></div>
                            <div class="menu-icon__line menu-icon__line--3"></div>
                        </div>
                    </a>
                </div>
            </header>

            <router-view></router-view>

            <div class="mini-footer">
                <div class="container">
                    <div class="mini-footer__wrapper">
                        <div class="mini-footer__item mini-footer__item--content">
                            Podporené z Európskeho sociálneho fondu v rámci operačného programu
                            Efektívna verejná správa prostredníctvom projektu
                            Dátové mesto pre participatívne územné plánovanie (transparentné –
                            výkonné – vnímavé).
                        </div>
                        <div class="mini-footer__item">
                            <img
                                class="mini-footer__image"
                                src="@/assets/home/eu-fond.png"
                                srcset="
                                        @/assets/home/eu-fond@3x.png 3x,
                                        @/assets/home/eu-fond@2x.png 2x,
                                        @/assets/home/eu-fond.png"
                                alt="logo-eu"
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="banner-footer">
                <a
                    href="https://www.alistiq.com"
                    target="_blank"
                >
                    <img v-if="this.$i18n.locale == 'sk'"
                        class="banner-footer__image"
                        src="@/assets/home/alistiq-banner.png"
                        srcset="
                            @/assets/home/alistiq-banner@3x.png 3x,
                            @/assets/home/alistiq-banner@2x.png 2x,
                            @/assets/home/alistiq-banner.png"
                        alt="Alistiq framework"
                    >
                    <img v-if="this.$i18n.locale == 'en'"
                        class="banner-footer__image"
                        src="@/assets/home/alistiq-banner-en.png"
                        srcset="
                            @/assets/home/alistiq-banner-en@3x.png 3x,
                            @/assets/home/alistiq-banner-en@2x.png 2x,
                            @/assets/home/alistiq-banner-en.png"
                        alt="Alistiq framework"
                    >
                </a>
            </div>

            <footer
                id="contact"
                class="footer"
            >
                <div class="container">
                    <div class="flex flex--grid footer__flex">
                        <div class="flex-1-2">
                            <img
                                src="@/assets/ecarewave/svg/map.svg"
                                alt=""
                                class="footer__image"
                            >
                            <div class="footer__info">
                                <div>Boston, USA</div>
                                <div>Hamburg, DE</div>
                                <div>Bratislava, SK</div>
                            </div>
                        </div>

                        <div class="flex-1-2 footer__column">
                            <div class="flex flex--grid footer__flex">
                                <div class="flex-1-2">
                                    <div class="partners">
                                        <a
                                            href="http://futuristiq.org/"
                                            class="partner__item"
                                        >
                                            <img
                                                class="partner__image"
                                                src="@/assets/logos/logo-futuristiq.png"
                                                srcset="
                                                @/assets/logos/logo-futuristiq@3x.png 3x,
                                                @/assets/logos/logo-futuristiq@2x.png 2x,
                                                @/assets/logos/logo-futuristiq.png"
                                                alt="logo-futuristique"
                                            >
                                        </a>

                                        <a
                                            href="http://www.atriumstudio.sk/"
                                            class="partner__item"
                                        >
                                            <img
                                                class="partner__image"
                                                src="@/assets/logos/logo-atrium-architect.png"
                                                srcset="
                                                @/assets/logos/logo-atrium-architect@3x.png 3x,
                                                @/assets/logos/logo-atrium-architect@2x.png 2x,
                                                @/assets/logos/logo-atrium-architect.png"
                                                alt="logo-atrium"
                                            >
                                        </a>

                                        <a
                                            href="https://www.pocitovemapy.sk/"
                                            class="partner__item"
                                        >
                                            <img
                                                class="partner__image"
                                                src="@/assets/logos/logo-pocitove-mapy.png"
                                                srcset="
                                                    @/assets/logos/logo-pocitove-mapy@3x.png 3x,
                                                    @/assets/logos/logo-pocitove-mapy@2x.png 2x,
                                                    @/assets/logos/logo-pocitove-mapy.png"
                                                alt="logo-pocitoveMapy"
                                            >
                                        </a>

                                        <a
                                            href="https://www.pocitovemapy.sk/"
                                            class="partner__item"
                                        >
                                            <img
                                                class="partner__image partner__image--svg"
                                                src="@/assets/logos/uzemneplany_sk_logo.svg"
                                                alt="logo-uzemneplany"
                                            >
                                        </a>
                                        <a
                                            href="https://www.alistiq.com"
                                            class="partner__item"
                                            target="_blank"
                                        >
                                            <img
                                                class="partner__image"
                                                src="@/assets/logos/logo-alistiq.png"
                                                srcset="
                                                    @/assets/logos/logo-alistiq@3x.png 3x,
                                                    @/assets/logos/logo-alistiq@2x.png 2x,
                                                    @/assets/logos/logo-alistiq.png"
                                                alt="logo-alistiq"
                                            >
                                        </a>
                                    </div>
                                </div>

                                <div class="flex-1-2">
                                    <h3>{{ $t('pages.home.menu.contact') }}</h3>
                                    <div class="footer__address">
                                        Futuristiq<br>
                                        Tallerova 10, 811 02 Bratislava<br>
                                        {{ $t('pages.home.slovakia') }}
                                    </div>
                                    <a
                                        href="mailto:info@futuristiq.org"
                                        class="footer__link"
                                    >
                                        info@futuristiq.org
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

        <div class="js-slide-menu-close"></div>
    </div>
</template>

<script>
import MenuLangSwitcher from '@/js/components/menu/MenuLangSwitcher';
import { LANGUAGES } from '@/js/constants/locale';

export default {
    LANGUAGES,
    components: {
        MenuLangSwitcher,
    },
};
</script>

<style
    scoped
    lang="less"
>
@import (reference) '../../less/variables';
@import '../../less/pages/home/app';

.page-preload .sr {
    visibility: hidden;
}

.main-wrapper {
    width: 100%;

    color: #728695;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 1.67;
    -webkit-font-smoothing: antialiased;

    @media (min-width: @break-t-min) {
        font-size: 18px;
    }
}

a.v-btn {
    text-decoration: none !important;
}

.menu-primary__item {
    display: flex;
    align-items: flex-end;
}

.header__lang-switcher {
    display: none;

    @media (max-width: @break-t-max) {
        display: flex;
        margin-top: 1px;
    }
}
</style>
