import Vue from 'vue';
import Vuex from 'vuex';

import { storage } from '@/js/utils/storage';
import { STORAGE } from '@/js/constants/storage';
import { FALLBACK_LANGUAGE } from '@/js/constants/locale';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

import user from './modules/user';
import map from './modules/map';

import { IS_DEVELOPMENT } from '../constants/app';

Vue.use(Vuex);

const state = {
    layoutLoadingCount: 0,
    localLoadingCount: 0,
    alertCount: 0,
    alertId: 0,
    alerts: {},
    language: storage.getItem(STORAGE.LANGUAGE) || FALLBACK_LANGUAGE,
};

export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions,

    modules: {
        user: {
            ...user,
            namespaced: true,
        },
        map: {
            ...map,
            namespaced: true,
        },
    },

    strict: IS_DEVELOPMENT,
});
