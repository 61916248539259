import { MARKER_ICONS } from '@/js/constants/icons';

export const TILESET_CODE = {
    DEFAULT: 'default',
    TOURIST: 'tourist',
    ORTO: 'ortofoto',
};

export const TILESETS = [
    {
        code: TILESET_CODE.DEFAULT,
        name: 'map.layerControl.tilesets.default',
        url: `https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${process.env.VUE_APP_MAPYCZ_API_KEY}`,
        options: {
            maxZoom: 18,
            center: [21.24364, 48.99265],
        },
        attribution: '&copy;Seznam.cz a.s., | &copy;OpenStreetMap '
            + '<a href="//mapy.cz">'
            + '   <img '
            + '      class="print" '
            + '      target="_blank" '
            + '      src="//api.mapy.cz/img/api/logo.svg" '
            + '      style="cursor: pointer; '
            + '      position:relative;top: 5px;"'
            + '   >'
            + '</a>',
        visible: true,
        icon: 'icon-map',
    },
    {
        code: TILESET_CODE.TOURIST,
        name: 'map.layerControl.tilesets.tourist',
        url: `https://api.mapy.cz/v1/maptiles/outdoor/256/{z}/{x}/{y}?apikey=${process.env.VUE_APP_MAPYCZ_API_KEY}`,
        options: {
            maxZoom: 18,
            center: [21.24364, 48.99265],
        },
        attribution: '&copy;Seznam.cz a.s., | &copy;OpenStreetMap '
            + '<a href="//mapy.cz">'
            + '   <img '
            + '      class="print" '
            + '      target="_blank" '
            + '      src="//api.mapy.cz/img/api/logo.svg" '
            + '      style="cursor: pointer; '
            + '      position:relative;top: 5px;"'
            + '   >'
            + '</a>',
        visible: false,
        icon: 'icon-direction',
    },
    {
        code: TILESET_CODE.ORTO,
        name: 'map.layerControl.tilesets.ortofoto',
        url: `https://api.mapy.cz/v1/maptiles/aerial/256/{z}/{x}/{y}?apikey=${process.env.VUE_APP_MAPYCZ_API_KEY}`,
        options: {
            maxZoom: 18,
            center: [21.24364, 48.99265],
        },
        attribution: '&copy;Seznam.cz a.s., | &copy;OpenStreetMap '
            + '<a href="//mapy.cz">'
            + '   <img '
            + '      class="print" '
            + '      target="_blank" '
            + '      src="//api.mapy.cz/img/api/logo.svg" '
            + '      style="cursor: pointer; '
            + '      position:relative;top: 5px;"'
            + '   >'
            + '</a>',
        visible: false,
        icon: 'icon-satellite',
    },
];

export const LEAFLET_MAP = {
    MAP: {
        ZOOM: 15,
        CENTER: [48.997344, 21.240549],
        OPTIONS: {
            attributionControl: false,
            zoomSnap: true,
            zoomControl: false,
            preferCanvas: true,
            continuousWorld: true,
            worldCopyJump: false,
            maxZoom: 18,
        },
        PROJECTION: {
            name: 'EPSG:102067',
            def: '+title=Krovak JTSK +proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813975277778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +units=m +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56 +no_defs <>',
        },
    },
};

export const LAYER_TAGS = {
    SUBJECTIVE: 'subjective',
    OBJECTIVE: 'objective',
};

export const LAYER_VIEW_TYPES = {
    DEFAULT: {
        name: 'map.layerControl.subLayers.default',
        code: 'marker',
        icon: 'marker-icon',
    },
    HEXGRID: {
        name: 'map.layerControl.subLayers.hexgrid',
        code: 'hexgrid',
        icon: 'hexagon-icon',
    },
    HEATMAP: {
        name: 'map.layerControl.subLayers.heatmap',
        code: 'heatmap',
        icon: 'heat-icon',
    },
};

export const ZELEN_OPTIONS = {
    weight: 1,
    color: '#282',
    opacity: 0.8,
};

export const MAP_OPTIONS = {
    CENTER: [21.24364, 48.99265],
    ZOOM: 12,
    LAYER_OPTIONS: {
        left: '10px',
        top: '15px',
    },
    CURRENT_POS_CONTROLLER: {
        top: '80px',
        right: '100px',
    },
    CURRENT_POS_CONTROLLER_QUESTION: {
        top: '40px',
        right: '100px',
    },
};

export const MAP_MARKERS_COLORS = {
    ACTIVE: '#096ea9',
    INACTIVE: '#424242',
};

export const MAP_MARKER_TYPES = {
    SINGLE: 'point',
    PATH: 'path',
    POLYLINE: 'linestring',
    POLYGON: 'polygon',
};

export const MAP_MARKER_TYPE_API_PAIRINGS = {
    POINT: {
        TYPE: MAP_MARKER_TYPES.SINGLE,
        TEXT: 'feelings.marker.single',
        ICON: MARKER_ICONS.POINT,
    },
    PATH: {
        TYPE: MAP_MARKER_TYPES.PATH,
        TEXT: 'feelings.marker.path',
        ICON: MARKER_ICONS.PATH,
    },
    LINESTRING: {
        TYPE: MAP_MARKER_TYPES.POLYLINE,
        TEXT: 'feelings.marker.polyline',
        ICON: MARKER_ICONS.LINESTRING,
    },
    POLYGON: {
        TYPE: MAP_MARKER_TYPES.POLYGON,
        TEXT: 'feelings.marker.polygon',
        ICON: MARKER_ICONS.POLYGON,
    },
    [MAP_MARKER_TYPES.POLYGON]: 'POLYGON',
    [MAP_MARKER_TYPES.PATH]: 'PATH',
    [MAP_MARKER_TYPES.POLYLINE]: 'LINESTRING',
    [MAP_MARKER_TYPES.SINGLE]: 'POINT',
};

export const MAP_CONTROL_ITEMS_DEFAULT = [
    {
        name: 'Zoom',
        options: [
            null,
            {
                showZoomMenu: false,
                titles: ['controls.zoomIn', 'controls.zoomOut'],
            },
        ],
        placement: {
            top: '80px',
            right: '20px',
        },
    },
    {
        name: 'ZoomNotification',
        options: [],
    },
];

export const MAP_CONTROL_ITEMS_DEFAULT_QUESTION = [
    {
        name: 'Zoom',
        options: [
            null,
            {
                showZoomMenu: false,
                titles: ['controls.zoomIn', 'controls.zoomOut'],
            },
        ],
        placement: {
            top: '40px',
            right: '20px',
        },
    },
    {
        name: 'ZoomNotification',
        options: [],
    },
];

export const MAP_SEARCH = {
    at: '48.997344,21.240549',
    in: 'countryCode:SVK',
    limit: 8,
    resultTypes: 'houseNumber,place,street,locality',
};

export const MAP_INSTANCE = {
    HOME: 'home',
    QUESTION: 'question',
};
