<template>
    <div class="leaflet">
        <l-map
            ref="map"
            class="leaflet__map"
            :zoom="zoom"
            :center="center"
            :options="$options.LEAFLET_MAP.MAP.OPTIONS"
            @ready="onReady"
            @baselayerchange="onLayerChange"
            @overlayadd="onOverlayAdd"
        >
            <l-control-zoom
                class="leaflet__control-zoom"
                position="topleft"
            ></l-control-zoom>

            <l-tile-layer
                v-for="tileset in $options.TILESETS"
                :key="`tileset-layer-${tileset.code}`"
                :ref="`layer-tileset-${tileset.code}`"
                :name="tileset.name"
                :url="tileset.url"
                :attribution="tileset.attribution"
                :options="tileset.options"
                :visible="activeTileset === tileset.code"
                layer-type="base"
            ></l-tile-layer>

            <l-control
                position="topright"
            >
                <div
                    class="current-position"
                    @click="setCurrentPosition"
                >
                    <div
                        class="btn current-position__button"
                        :class="{'is-active': currentPos}"
                    >
                        <v-icon>
                            mdi-crosshairs-gps
                        </v-icon>
                    </div>
                </div>
            </l-control>

            <!--Single markers-->
            <l-marker
                v-if="currentPos"
                :lat-lng="currentPos"
            >
                <l-icon
                    class-name="someExtraClass"
                >
                    <marker-leaflet :type="$options.MAP_MARKER_TYPES.SINGLE"></marker-leaflet>
                </l-icon>
            </l-marker>

            <l-marker
                v-if="localSelectedPlace"
                :lat-lng="localSelectedPlace.position"
            >
                <l-icon
                    class-name="leaflet__place"
                >
                    <marker-leaflet :type="$options.MAP_MARKER_TYPES.SINGLE"></marker-leaflet>
                </l-icon>
            </l-marker>
            <!--./Single markers-->

            <!--Entities-->
            <l-layer-group
                v-for="layer in localLayers"
                :key="`layer_emotion_${layer.id}`"
                ref="layer"
                :data-ref="`ref_${layer.id}`"
                :name="`emotionLayer_${layer.id}`"
            >
                <l-feature-group
                    :key="layer.view"
                    ref="layer-view"
                    :data-ref="`ref-${layer.id}-${layer.view}`"
                >
                </l-feature-group>
            </l-layer-group>
            <!--./Entities-->
        </l-map>
    </div>
</template>

<script>
import {
    LMap,
    LTileLayer,
    LControlZoom,
    LControl,
    LFeatureGroup,
    LMarker,
    LIcon,
    LLayerGroup,
} from 'vue2-leaflet';
import {
    latLng,
} from 'leaflet';
import {
    LEAFLET_MAP,
    MAP_MARKER_TYPES,
    ZELEN_OPTIONS,
    LAYER_VIEW_TYPES,
    TILESETS, TILESET_CODE,
} from '@/js/constants/map';
import { LAYERS, EMOTION_QUESTIONS } from '@/js/constants/mocks';
import { ALERT_TYPES } from '@/js/constants/alert';
import MarkerLeaflet from '@/js/components/markers/MarkerLeaflet';
import 'proj4leaflet';
import '@/js/plugins/heatMap';
import '@asymmetrik/leaflet-d3';
import renderHexgrid from '@/js/mixins/renderHexgrid';
import renderHeatmap from '@/js/mixins/renderHeatmap';
import { cleanGeoJson, prepareGeoJSONData } from '@/js/helpers/geojson';

export default {
    LEAFLET_MAP,
    MAP_MARKER_TYPES,
    ZELEN_OPTIONS,
    LAYER_VIEW_TYPES,
    LAYERS,
    EMOTION_QUESTIONS,
    TILESETS,

    components: {
        LMap,
        LTileLayer,
        LControlZoom,
        LControl,
        LFeatureGroup,
        LMarker,
        LIcon,
        MarkerLeaflet,
        LLayerGroup,
    },
    mixins: [renderHeatmap, renderHexgrid],
    props: {
        selectedPlace: {
            type: Object,
            default: null,
        },
        layers: {
            type: Array,
            default: null,
        },
        activeLayers: {
            type: Object,
            default: null,
        },
    },
    data() {
        const localSelectedPlace = this.selectedPlace;
        const defaultPointOptions = (layer, ref) => ({
            style() {
                return {
                    color: layer.color,
                    weight: 2,
                    fillColor: layer.color,
                    stroke: true,
                    pane: 'markerPane',
                };
            },
            pointToLayer(feature, latlng) {
                return L.circleMarker(latlng, {
                    color: layer.color,
                    radius: 4,
                    stroke: false,
                    // TODO: Maybe this can be sent by BE
                    weight: 2,
                    fillOpacity: 0.8,
                    pane: 'markerPane',
                });
            },
            onEachFeature: this.onEachFeature.bind(null, layer, ref),
        });

        return {
            bounds: null,
            center: this.$route.query.center?.split(',') || LEAFLET_MAP.MAP.CENTER,
            zoom: +this.$route.query.zoom || LEAFLET_MAP.MAP.ZOOM,
            currentPos: null,
            markers: null,
            placePos: null,
            popupPos: null,
            activeTileset: TILESET_CODE.DEFAULT,
            localSelectedPlace,
            localLayers: [],
            defaultPointOptions,
        };
    },
    computed: {
        isVisibleLayer() {
            return (layer, view) => {
                if (this.activeLayers[layer.id]) {
                    return this.activeLayers[layer.id] === view;
                }
                return false;
            };
        },
    },
    watch: {
        activeLayers: {
            handler(v) {
                if (v) {
                    this.setLocalLayers(v);
                }
            },
            deep: true,
        },
        selectedPlace(v) {
            if (v) {
                this.center = v.position;
                this.zoom = 14;

                this.localSelectedPlace = v;
                this.$refs.map.mapObject.setView(this.center, this.zoom);
            } else {
                this.localSelectedPlace = null;
            }
        },
    },
    created() {
        this.embedLayout = !!this.$route.query.embed;
        this.$root.$on('switch-active-tile-set', this.setActiveTileSet);
        this.$root.$on('map-render-layer', this.renderLayer);
        this.$root.$on('map-reset-layers', this.resetLayers);
    },
    beforeDestroy() {
        this.$root.$off('switch-active-tile-set', this.setActiveTileSet);
        this.$root.$off('map-render-layer', this.renderLayer);
        this.$root.$off('map-reset-layers', this.resetLayers);
    },
    methods: {
        setLocalLayers(layers) {
            this.localLayers = [];
            Object.entries(layers).forEach(([id, view]) => {
                const item = this.layers.find((layer) => layer.id === id);
                item.view = view;

                if (item) {
                    this.localLayers.push(item);
                }
            });
        },
        getRefFromIteration(group, name) {
            if (!this.$refs[group]) {
                return null;
            }

            return this.$refs[group].find((item) => item.$el.dataset.ref === name);
        },
        onEachFeature(data, ref, feature, layer) {
            let template = null;

            if (data.view === 'marker') {
                template = this.processTemplate(
                    data.template,
                    feature.properties,
                );
            }

            if (!template) return;

            // TODO: change to highlight color
            layer.options.color = '#900';
            layer.options.weight = 1;

            layer.bindPopup(template);
        },
        processTemplate(template, properties) {
            if (!template || template === '' || template === '{{}}') {
                return null;
            }

            /* eslint-disable no-useless-escape */
            const regex = new RegExp(Object.keys(properties)
                .map((key) => `{{\\s?${key}\\s?}}`)
                .join('|'), 'gm');
            /* eslint-enable no-useless-escape */
            const processed = template.replace(regex, (matched) => properties[
                matched.replace(/[{{,}}]+/g, '')
            ]);

            if (!processed || processed === '') {
                return null;
            }

            const div = document.createElement('div');
            div.innerHTML = processed;
            return div;
        },
        renderLayer({ layer }) {
            const refName = `ref-${layer.id}-${layer.view}`;
            const ref = this.getRefFromIteration('layer-view', refName);

            if (!ref) {
                console.error('Missing ref');
            }

            this.renderView(
                this.prepareGeoJSON(layer, ref),
                {
                    layer,
                    view: layer.view,
                    ref,
                },
            );
        },
        prepareGeoJSON(layerData, ref) {
            if (layerData.features) {
                layerData.features = cleanGeoJson(layerData.features);
            }

            return prepareGeoJSONData(
                layerData,
                {
                    options: this.defaultPointOptions(layerData, ref),
                },
            );
        },
        // TODO: Refactor into mixin
        renderView(geojson, {
            layer,
            view,
            weightCode,
            maxWeight,
            heatmapRadius,
            heatmapOpacity,
            ref,
        }) {
            if (view === LAYER_VIEW_TYPES.DEFAULT.code) {
                this.renderDefault({
                    geojson,
                    ref,
                });
            } else if (view === LAYER_VIEW_TYPES.HEXGRID.code) {
                this.renderHexgrid({
                    geojson: geojson.toGeoJSON(),
                    ref,
                    layer,
                });
            } else if (view === LAYER_VIEW_TYPES.HEATMAP.code) {
                this.renderHeatmap({
                    geojson: geojson.toGeoJSON(),
                    ref,
                    weightCode,
                    maxWeight,
                    heatmapRadius,
                    heatmapOpacity,
                });
            }
        },
        renderDefault({
            geojson,
            ref,
        }) {
            ref?.mapObject.addLayer(geojson);
        },
        renderHexgrid({
            geojson,
            ref,
            layer,
        }) {
            this.renderDataHexgrid(
                geojson,
                ref?.mapObject,
                layer,
            );
        },
        renderHeatmap({
            geojson,
            ref,
            weightCode,
            maxWeight,
            heatmapRadius,
            heatmapOpacity,
        }) {
            this.renderDataHeatmap(geojson, {
                layer: ref?.mapObject,
                weightCode,
                maxWeight,
                heatmapRadius,
                heatmapOpacity,
            });
        },
        onReady() {
            this.$emit('ready');
        },
        onLayerChange() {
            console.log('Layer changed');
        },
        onOverlayAdd() {
            console.log('Overlay added');
        },
        setActiveTileSet(code) {
            this.activeTileset = code;
        },
        resetLayers() {
            this.$refs['layer-view'].forEach((item) => {
                item.mapObject?.clearLayers();
            });
        },
        setCurrentPosition() {
            if (this.currentPos) {
                this.currentPos = null;

                return;
            }

            if ('geolocation' in navigator) {
                this.isLoading = true;

                navigator.geolocation.getCurrentPosition((position) => {
                    this.center = latLng(position.coords.latitude, position.coords.longitude);
                    this.currentPos = this.center;
                    this.zoom = 18;

                    this.$refs.map.mapObject.setView(this.center, this.zoom);

                    this.isLoading = false;
                }, (err) => {
                    // eslint-disable-next-line no-console
                    console.error(err);

                    this.$store.dispatch('doAlert', {
                        text: 'Nastal problém so zameriavaním vašej polohy',
                        type: ALERT_TYPES.ERROR,
                    });

                    this.isLoading = false;
                });
            } else {
                this.$store.dispatch('doAlert', {
                    text: 'Váš prehliadač bohužiaľ nepodporuje funkciu získavania polohy',
                    type: 'error',
                });
            }
        },
    },
};
</script>

<style lang="less">
@import '../../../less/variables';
@import '~leaflet/dist/leaflet.css';
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.leaflet {
    position: relative;
    z-index: 1;

    flex: 1 1 auto;
}

.leaflet--home {
    .leaflet-control-zoom,
    .leaflet-control-layers {
        margin-top: 80px;
    }

    .leaflet-control-zoom {
        margin-left: 15px;
    }

    .leaflet-control-layers {
        margin-right: 15px;
    }

    .leaflet-control {
        margin-right: 45px;
        margin-top: 0;

        .current-position {
            position: static;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        }
    }

    .marker--current,
    .marker--linestring,
    .marker--polygon {
        position: static;
    }

    .leaflet-control--layers {
        margin-right: 0;
    }
}

.leaflet-control {
    position: relative;
    z-index: 1;
}

.leaflet__control {
    min-width: 200px;
    height: 100%;
    overflow: auto;
}

.hexbin-hexagon {
    stroke: #000;
    stroke-width: 1px;
}

.leaflet-top.leaflet-right {
    padding: 80px 20px;
    display: flex;
}

.leaflet-top.leaflet-left {
    padding: 80px 0;
}

.leaflet__control-close {
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 1;
}

.leaflet-control--layers {
    height: 100%;
}

.home--menu-opened {
    .leaflet-right {
        @media (max-width: @break-s-max) {
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .leaflet-control--layers {
        @media (max-width: @break-s-max) {
            position: absolute;
            top: 70px;
            left: 0;
            right: 0;
            z-index: 1;
        }
    }
}
</style>
