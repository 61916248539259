import { ROUTE } from '@/js/constants/route';

export const ERROR_ICON = `<i
    aria-hidden="true"
    class="v-icon material-icons theme--light error--text"
    style="font-size: 16px;
    margin-right: 1px;"
>error</i>`;

export const USER_LOGIN = {
    ITEMS: {
        email: {
            type: 'email',
            name: 'email',
            icon: 'mdi-email',
            required: true,
            validators: [
                (t, v) => !!v || t('form.email.presenceValidation'),
                (t, v) => /.+@.+\..+/.test(v) || t('form.email.formatValidation'),
            ],
            label: 'form.email.label',
        },
        password: {
            type: 'password',
            name: 'password',
            icon: 'mdi-lock',
            required: true,
            validators: [
                (t, v) => !!v || t('form.password.presenceValidation'),
            ],
            label: 'form.password.label',
        },
    },
};

export const USER_REGISTER = {
    ITEMS: {
        name: {
            type: 'text',
            name: 'name',
            icon: 'mdi-account',
            required: true,
            validators: [
                (t, v) => (v && v.length >= 3) || t('form.common.min3CharsValidation'),
                (t, v) => !!v || t('form.name.presenceValidation'),
            ],
            label: 'form.name.label',
        },
        email: {
            type: 'email',
            name: 'email',
            icon: 'mdi-email',
            required: true,
            validators: [
                (t, v) => !!v || t('form.email.presenceValidation'),
                (t, v) => /.+@.+\..+/.test(v) || t('form.email.formatValidation'),
            ],
            label: 'form.email.label',
        },
        password: {
            type: 'password',
            name: 'password1',
            icon: 'mdi-lock',
            hint: 'form.password.hint',
            required: true,
            validators: [
                (t, v) => !!v || t('form.password.presenceValidation'),
                (t, v) => (v && v.length >= 8) || t('form.common.min8CharsValidation'),
            ],
            label: 'form.password.label',
        },
        confirmPassword: {
            type: 'password',
            name: 'password2',
            icon: 'mdi-check',
            required: true,
            validators: [
                (t, v) => !!v || t('form.password.presence2Validation'),
            ],
            label: 'form.password.label2',
        },
        permanentAddressCity: {
            type: 'text',
            name: 'permanentAddressCity',
            icon: 'mdi-city',
            required: true,
            validators: [
                (t, v) => !!v || t('form.address.presenceValidation'),
            ],
            label: 'form.address.label',
        },
        phone: {
            type: 'text',
            name: 'phone',
            validators: [
                (t, v) => (v && v.length >= 3) || t('form.common.min3CharsValidation'),
            ],
            label: 'form.phone.label',
        },
        consent: {
            type: 'checkbox',
            name: 'consent',
            required: true,
            file: './documents/podmienky_ochrany_sukromia_participativny_rozpocet.pdf',
            validators: [
                (t, v) => !!v || t('form.consent.presenceValidation'),
            ],
            label: 'form.consent.label',
        },
    },
};

export const USER_PROFILE = {
    ITEMS: {
        [ROUTE.PROFILE_NAME]: {
            type: 'text',
            name: 'name',
            icon: 'mdi-account',
            autocomplete: 'name',
            validators: [
                (t, v) => (v && v.length >= 3) || t('form.common.min3CharsValidation'),
            ],
            title: 'form.name.change.title',
            label: 'form.name.change.label',
            submit: 'form.name.change.submit',
            success: 'form.name.change.success',
        },
        [ROUTE.PROFILE_MAIL]: {
            type: 'email',
            name: 'email',
            icon: 'mdi-email',
            autocomplete: 'email username',
            validators: [
                (t, v) => /.+@.+\..+/.test(v) || t('form.email.formatValidation'),
            ],
            title: 'form.email.change.title',
            label: 'form.email.change.label',
            submit: 'form.email.change.submit',
            success: 'form.email.change.success',
        },
        oldPassword: {
            type: 'password',
            name: 'oldPassword',
            icon: 'mdi-lock-open',
            autocomplete: 'current-password',
            validators: [
                (t, v) => !!v || t('form.password.presence3Validation'),
            ],
            label: 'form.password.change.labelCurrent',
        },
        password1: {
            type: 'password',
            name: 'password1',
            icon: 'mdi-lock',
            hint: 'form.password.hint',
            autocomplete: 'new-password',
            validators: [
                (t, v) => (!v || (v && v.length >= 8)) || t('form.common.min8CharsValidation'),
            ],
            label: 'form.password.change.label',
        },
        password2: {
            type: 'password',
            name: 'password2',
            icon: 'mdi-check',
            autocomplete: 'new-password',
            validators: [
                (t, v) => !!v || t('form.password.presence2Validation'),
            ],
            label: 'form.password.change.label2',
        },
        // autocomplete for sms code - https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#attr-fe-autocomplete-one-time-code
        // this will auto import code from a sms
        [ROUTE.PROFILE_PHONE]: {
            type: 'tel',
            name: 'phone',
            icon: 'mdi-phone-enabled',
            autocomplete: 'tel',
            validators: [
                (t, v) => (v && v.length >= 3) || t('form.common.min3CharsValidation'),
            ],
            title: 'form.phone.change.title',
            label: 'form.phone.change.label',
            submit: 'form.phone.change.submit',
            success: 'form.phone.change.success',
        },
        [ROUTE.PROFILE_CITY]: {
            type: 'text',
            name: 'permanentAddressCity',
            icon: 'mdi-city',
            autocomplete: 'address-level2',
            validators: [
                (t, v) => !!v || t('form.address.presenceValidation'),
            ],
            title: 'form.address.change.title',
            label: 'form.address.change.label',
            submit: 'form.address.change.submit',
            success: 'form.address.change.success',
        },
    },
};

export const USER_FORGOT = {
    ITEMS: {
        email: {
            ...USER_LOGIN.ITEMS.email,
            validators: [
                ...USER_LOGIN.ITEMS.email.validators,
            ],
        },
    },
};

export const USER_RESET = {
    ITEMS: {
        password: {
            ...USER_REGISTER.ITEMS.password,
            validators: [
                ...USER_REGISTER.ITEMS.password.validators,
            ],
        },
        confirmPassword: {
            ...USER_REGISTER.ITEMS.confirmPassword,
            validators: [
                ...USER_REGISTER.ITEMS.confirmPassword.validators,
            ],
        },
    },
};

export const DEMOGRAPHY = {
    ITEMS: {
        age: {
            name: 'ageCategoryId',
            label: 'form.age.label',
            validators: [
                (t, v) => !!v || t('form.age.presenceValidation'),
            ],
        },
        gender: {
            name: 'gender',
            label: 'form.gender.label',
            validators: [
                (t, v) => !!v || t('form.gender.presenceValidation'),
            ],
        },
        email: {
            ...USER_REGISTER.ITEMS.email,
            validators: [
                (t, v) => !v || /.+@.+\..+/.test(v) || t('form.email.formatValidation'),
            ],
            required: false,
        },
    },
};
