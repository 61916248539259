<template>
    <div
        class="marker"
        :class="{
            [`marker--${type}`]: true,
            'is-active': isActive,
        }"
    >
        <div
            class="marker__inner"
        >
            <v-icon
                v-if="type === $options.MAP_MARKER_TYPES.SINGLE"
                class="marker__icon"
                :color="isActive ? 'primary' : 'grey darken-3'"
            >
                mdi-map-marker
            </v-icon>

            <v-icon
                v-else
                class="marker__icon"
                :color="isActive ? 'primary' : 'grey darken-3'"
            >
                icon-marker
            </v-icon>
        </div>
    </div>
</template>

<script>
import { MAP_MARKER_TYPES } from '../../constants/map';

export default {
    MAP_MARKER_TYPES,

    props: {
        type: {
            type: String,
            default: null,
        },
    },

    data() {
        const { parentId, canDelete } = this;

        return {
            state: {
                parentId,
                canDelete,
            },
        };
    },
    computed: {
        isActive() {
            return false;
        },
        activeId() {
            return false;
        },
        activeMode() {
            return false;
        },
    },
};
</script>
