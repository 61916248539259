var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-speed-dial',{attrs:{"color":"primary","direction":"top","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(_vm.canSave)?_c('v-btn',_vm._g({attrs:{"color":"secondary","dark":"","fab":""}},!_vm.canSave
                ? { click: _vm.preventAnyAction }
                : { click: _vm.showDialog }),[_c('v-icon',[_vm._v(_vm._s(_vm.$options.MISC_ICONS.MARKER_CONTROLS_CONFIRM))])],1):_c('v-btn',_vm._g({staticClass:"speed-dial__button",class:{
                'v-btn--disabled reset-pointer-events cursor-default': _vm.speedDialDisabled
            },attrs:{"color":"primary","dark":"","fab":""}},_vm.speedDialDisabled
                ? { click: _vm.preventAnyAction }
                : null),[(!_vm.toggler)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.getIconByActiveType)+" ")]):_c('v-icon',[_vm._v(" mdi-arrow-down ")])],1)]},proxy:true}]),model:{value:(_vm.toggler),callback:function ($$v) {_vm.toggler=$$v},expression:"toggler"}},_vm._l((_vm.question.markers),function(item,index){return _c('v-btn',{key:index,staticClass:"mb-4",attrs:{"color":_vm.isButtonEnabled(_vm.$options.MAP_MARKER_TYPE_API_PAIRINGS[item.type].TYPE)
            ? 'secondary'
            : 'primary',"disabled":_vm.isButtonEnabled(_vm.$options.MAP_MARKER_TYPE_API_PAIRINGS[item.type].TYPE),"fab":"","small":""},on:{"click":function($event){return _vm.prepareMarkerType(
            _vm.$options.MAP_MARKER_TYPE_API_PAIRINGS[item.type].TYPE
        )}}},[_c('v-icon',{attrs:{"color":"white","size":"18"}},[_vm._v(" "+_vm._s(_vm.$options.MAP_MARKER_TYPE_API_PAIRINGS[item.type].ICON)+" ")])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }