var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-layer-control",class:{'is-open': _vm.layerControlVisible}},[_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('mapLayerControl.tooltip'),
            show: _vm.tooltipToggle,
            trigger: 'manual',
            placement: 'bottom',
            classes: ['map-layer-control__tooltip'],
        }),expression:"{\n            content: $t('mapLayerControl.tooltip'),\n            show: tooltipToggle,\n            trigger: 'manual',\n            placement: 'bottom',\n            classes: ['map-layer-control__tooltip'],\n        }"}],staticClass:"map-layer-control__btn my-0",attrs:{"color":"white","fab":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.toggleNavigation.apply(null, arguments)}}},[_vm._t("default")],2),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.layerControlVisible),expression:"layerControlVisible"}],staticClass:"map-layer-control__container pa-0"},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',[_c('layer-control',{attrs:{"active-layers":_vm.activeLayers,"subjective-data":_vm.subjectiveData,"objective-data":_vm.objectiveData},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('v-btn',{staticClass:"map-layer-control__close",attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();return _vm.toggleNavigation.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }