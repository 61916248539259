import axios from 'axios';
import { API } from '@/js/constants/api';

export default {
    methods: {
        loadUser(cb) {
            axios.get(API.USER)
                .then((user) => {
                    this.$store.dispatch('user/doSetUser', user.data);

                    if (cb) {
                        cb();
                    }
                })
                .catch(({ message, response }) => {
                    if (response && response.status === 401) {
                        this.$store.dispatch('user/doLogout');

                        this.registerSyntheticUser(cb);
                    } else {
                        this.$store.dispatch('doAlert', {
                            type: 'error',
                            text: this.errorMessage(message),
                        });
                    }
                });
        },
        async registerSyntheticUser(cb) {
            try {
                const { data } = await axios.post(API.REGISTER_SU, {
                    utm: '',
                });

                if (data) {
                    this.$store.dispatch('user/doLogin',
                        { token: data.token });
                }

                this.loadUser(cb);
            } catch ({ message, response }) {
                if (response && response.status === 401) {
                    this.$store.dispatch('user/doLogout');
                } else {
                    this.$store.dispatch('doAlert', {
                        type: 'error',
                        text: this.errorMessage(message),
                    });
                }
            }
        },
    },
};
