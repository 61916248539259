import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';

import { scrollToHash } from '@/js/helpers/scroll';
import Map from './views/Map';
import Campaigns from './views/Campaigns';
import Campaign from './views/Campaign';
import CampaignLayout from './views/CampaignLayout';
import Ecarewave from './views/Ecarewave';
import EcarewaveIndex from './views/EcarewaveIndex';
import EcarewaveAbout from './views/EcarewaveAbout';
import Guidelines from './views/Guidelines';
import Questions from './views/Questions';
import Question from './views/Question';
import Demography from './views/Demography';

import AuthLayout from './views/user/AuthLayout';
import Activate from './views/user/Activate';
import Login from './views/user/Login';
import LoginExternal from './views/user/LoginExternal';
import Register from './views/user/Register';
import Forgot from './views/user/Forgot';
import Reset from './views/user/Reset';
import ProfileLayout from './views/user/ProfileLayout';
import ProfileEdit from './views/user/ProfileEdit';
import ProfilePassword from './views/user/ProfilePassword';
import ProfileRemoveAccount from './views/user/ProfileRemoveAccount';
import Profile from './views/user/Profile';

import store from './store';
import { ROUTE } from './constants/route';
import { API } from './constants/api';

Vue.use(Router);

function loadUser(cb) {
    axios.get(API.USER)
        .then((user) => {
            store.dispatch('user/doSetUser', user.data);

            if (cb) {
                cb();
            }
        })
        .catch(({ response }) => {
            if (response && response.status === 401) {
                store.dispatch('user/doLogout');
            }
        });
}

async function registerSyntheticUser() {
    try {
        const { data } = await axios({
            url: API.REGISTER_SU,
            method: 'post',
        });

        if (data) {
            await store.dispatch('user/doLogin',
                { token: data.token });
        }

        loadUser();
    } catch (error) {
        throw new Error(error);
    }
}

const router = new Router({
    mode: 'history',
    routes: [
        { path: `/${ROUTE.GUIDELINES}`, component: Guidelines },
        { path: '*', component: Ecarewave, redirect: '/' },
        {
            path: '/',
            component: Ecarewave,
            children: [
                {
                    path: '',
                    name: ROUTE.ECAREWAVE_INDEX,
                    component: EcarewaveIndex,
                    meta: {
                        isEcarewave: true,
                    },
                },
                {
                    path: ROUTE.ECAREWAVE_ABOUT,
                    name: ROUTE.ECAREWAVE_ABOUT,
                    component: EcarewaveAbout,
                    meta: {
                        isEcarewave: true,
                    },
                },
            ],
        },
        {
            path: `/${ROUTE.MAP}`,
            name: ROUTE.MAP,
            component: Map,
        },
        // AUTH MODULE,
        {
            path: '',
            component: AuthLayout,
            children: [
                {
                    path: ROUTE.LOGIN,
                    name: ROUTE.LOGIN,
                    component: Login,
                    meta: {
                        logged: true,
                        loggedSynthetic: true,
                    },
                },
                {
                    path: ROUTE.LOGIN_EXTERNAL,
                    name: ROUTE.LOGIN_EXTERNAL,
                    component: LoginExternal,
                    meta: {
                        noRedirect: true,
                    },
                },
                {
                    path: ROUTE.REGISTER,
                    name: ROUTE.REGISTER,
                    component: Register,
                    meta: {
                        logged: true,
                        loggedSynthetic: true,
                    },
                },
                {
                    path: ROUTE.FORGOT,
                    name: ROUTE.FORGOT,
                    component: Forgot,
                    meta: {
                        logged: true,
                        loggedSynthetic: true,
                    },
                },
                {
                    path: ROUTE.RESET,
                    name: ROUTE.RESET,
                    component: Reset,
                    meta: {
                        logged: true,
                        loggedSynthetic: true,
                    },
                },
                {
                    path: `${ROUTE.ACTIVATE}`,
                    name: ROUTE.ACTIVATE,
                    component: Activate,
                },
                {
                    path: ROUTE.PROFILE,
                    component: ProfileLayout,
                    meta: {
                        auth: true,
                        loggedSynthetic: true,
                    },
                    children: [
                        {
                            path: '',
                            name: ROUTE.PROFILE,
                            component: Profile,
                        },
                        {
                            path: 'name',
                            name: ROUTE.PROFILE_NAME,
                            component: ProfileEdit,
                        },
                        {
                            path: 'email',
                            name: ROUTE.PROFILE_MAIL,
                            component: ProfileEdit,
                        },
                        {
                            path: 'password',
                            name: ROUTE.PROFILE_PASSWORD,
                            component: ProfilePassword,
                        },
                        {
                            path: 'phone',
                            name: ROUTE.PROFILE_PHONE,
                            component: ProfileEdit,
                        },
                        {
                            path: 'city',
                            name: ROUTE.PROFILE_CITY,
                            component: ProfileEdit,
                        },
                        {
                            path: 'remove-account',
                            name: ROUTE.PROFILE_REMOVE_ACCOUNT,
                            component: ProfileRemoveAccount,
                        },
                    ],
                },
            ],
        },
        // ./AUTH MODULE
        {
            path: `/${ROUTE.CAMPAIGNS}`,
            name: ROUTE.CAMPAIGNS,
            component: Campaigns,
            meta: {
                auth: true,
            },
        },
        {
            path: `/${ROUTE.CAMPAIGN}/:cid`,
            component: CampaignLayout,
            meta: {
                auth: true,
            },
            children: [
                {
                    path: '',
                    name: ROUTE.CAMPAIGN,
                    component: Campaign,
                },
                {
                    path: ROUTE.QUESTIONS,
                    name: ROUTE.QUESTIONS,
                    component: Questions,
                },
                {
                    path: `${ROUTE.QUESTION}/:qid`,
                    name: ROUTE.QUESTION,
                    component: Question,
                },
                {
                    path: ROUTE.DEMOGRAPHY,
                    name: ROUTE.DEMOGRAPHY,
                    component: Demography,
                },
            ],
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (to.hash) {
            setTimeout(() => {
                scrollToHash(to.hash);
            }, 100);

            return null;
        }

        return {
            x: 0,
            y: 0,
        };
    },
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (store.getters['user/isLoggedSynthetic']) {
            next();

            return;
        }
    }

    if (to.matched.some(record => record.meta.auth)) {
        if (store.getters['user/isLogged']) {
            next();
            return;
        }

        try {
            await registerSyntheticUser();

            next();
        } catch (e) {
            next('/login');
        }
    } else if (to.matched.some(record => record.meta.logged)
        && to.matched.some(record => record.meta.loggedSynthetic)) {
        if (!store.getters['user/isLoggedSynthetic']
            && store.getters['user/isLogged']) {
            next('/');
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
