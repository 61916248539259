export default {
    methods: {
        errorMessage(error) {
            if (error && error.response) {
                const { data } = error.response;
                const { errorCode } = data;

                return errorCode
                    ? this.$t('network.error', { reason: this.$t(`api.error.${errorCode}`) })
                    : this.$t('network.unknownError');
            }

            return null;
        },
        emitSuccessfulState(response) {
            this.$emit('success', response);
        },
        emitErrorState(error) {
            this.$emit('error', this.errorMessage(error));
        },
    },
};
