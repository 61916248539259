<template>
    <v-form
        ref="form"
        @submit.prevent="validate"
    >
        <v-layout column>
            <v-flex>
                <v-text-field
                    v-model="formData.name"
                    :prepend-icon="$options.ITEMS.name.icon"
                    :type="$options.ITEMS.name.type"
                    :rules="trans($options.ITEMS.name.validators)"
                    required
                    autofocus
                    autocomplete="name"
                >
                    <template #label>
                        {{ $t($options.ITEMS.name.label) }}
                        <span
                            v-if="$options.ITEMS.name.required"
                            class="error--text"
                        >*</span>
                    </template>

                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            mdi-alert-circle
                        </v-icon>
                        {{ message }}
                    </template>
                </v-text-field>
            </v-flex>

            <v-flex>
                <v-text-field
                    v-model="formData.email"
                    :prepend-icon="$options.ITEMS.email.icon"
                    :type="$options.ITEMS.email.type"
                    :rules="trans($options.ITEMS.email.validators)"
                    error-count="3"
                    required
                    autocomplete="email username"
                >
                    <template #label>
                        {{ $t($options.ITEMS.email.label) }}
                        <span
                            v-if="$options.ITEMS.email.required"
                            class="error--text"
                        >*</span>
                    </template>

                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            mdi-alert-circle
                        </v-icon>
                        {{ message }}
                    </template>
                </v-text-field>
            </v-flex>

            <v-flex>
                <v-text-field
                    ref="password"
                    v-model="formData.password1"
                    :prepend-icon="$options.ITEMS.password.icon"
                    :append-icon="isVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="isVisible ? 'text' : $options.ITEMS.password.type"
                    :rules="[...trans($options.ITEMS.password.validators),
                             validatorConfirmPassword]"
                    :required="$options.ITEMS.password.required"
                    :hint="$t($options.ITEMS.password.hint)"
                    autocomplete="new-password"
                    error-count="3"
                    persistent-hint
                    class="mb-2"
                    @click:append="togglePasswordVisibility"
                >
                    <template #label>
                        {{ $t($options.ITEMS.password.label) }}
                        <span
                            v-if="$options.ITEMS.password.required"
                            class="error--text"
                        >*</span>
                    </template>

                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            mdi-alert-circle
                        </v-icon>
                        {{ message }}
                    </template>
                </v-text-field>
            </v-flex>

            <v-flex>
                <v-text-field
                    ref="confirmPassword"
                    v-model="formData.password2"
                    :prepend-icon="$options.ITEMS.confirmPassword.icon"
                    :type="$options.ITEMS.confirmPassword.type"
                    :rules="trans($options.ITEMS.confirmPassword.validators)"
                    required
                    autocomplete="new-password"
                >
                    <template #label>
                        {{ $t($options.ITEMS.confirmPassword.label) }}
                        <span
                            v-if="$options.ITEMS.confirmPassword.required"
                            class="error--text"
                        >*</span>
                    </template>

                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            mdi-alert-circle
                        </v-icon>
                        {{ message }}
                    </template>
                </v-text-field>
            </v-flex>

            <v-flex class="mb-3">
                <v-text-field
                    v-model="formData.permanentAddressCity"
                    :prepend-icon="$options.ITEMS.permanentAddressCity.icon"
                    :type="$options.ITEMS.permanentAddressCity.type"
                    :rules="trans($options.ITEMS.permanentAddressCity.validators)"
                    required
                    autocomplete="address-level2"
                >
                    <template #label>
                        {{ $t($options.ITEMS.permanentAddressCity.label) }}
                        <span
                            v-if="$options.ITEMS.permanentAddressCity.required"
                            class="error--text"
                        >*</span>
                    </template>

                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            mdi-alert-circle
                        </v-icon>
                        {{ message }}
                    </template>
                </v-text-field>
            </v-flex>

            <v-flex>
                <v-checkbox
                    v-model="formData.consent"
                    :rules="trans($options.ITEMS.consent.validators)"
                    required
                >
                    <template #label>
                        <!--eslint-disable-->
                        <a
                            target="_blank"
                            @click.stop
                            :href="$options.ITEMS.consent.file"
                        >
                            {{ $t($options.ITEMS.consent.label) }}
                        </a>
                        <span class="error--text form-checkbox__required-icon">
                            *
                        </span>
                    </template>

                    <template #message="{message}">
                        <v-icon
                            color="red"
                            size="16"
                            class="error-message__icon"
                        >
                            mdi-alert-circle
                        </v-icon>
                        {{ message }}
                    </template>
                </v-checkbox>
            </v-flex>

            <v-flex
                mb-4
                class="text-left text-smedium grey--text text--darken-1"
            >
                <span class="error--text">*</span>
                {{ $t('form.mandatoryFields') }}
            </v-flex>

            <v-flex py-4>
                <v-layout
                    justify-space-between
                    row
                >
                    <v-flex
                        shrink
                    >
                        <v-btn
                            color="primary"
                            rounded
                            text
                            :to="{name: $options.ROUTE.LOGIN}"
                        >
                            {{ $t('registration.back') }}
                        </v-btn>
                    </v-flex>

                    <v-flex
                        shrink
                    >
                        <v-btn
                            rounded
                            color="secondary"
                            type="submit"
                            :loading="isLoading"
                            :disabled="isLoading"
                        >
                            {{ $t('registration.submit') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import axios from 'axios';
import { ROUTE } from '@/js/constants/route';
import { ERROR_ICON, USER_REGISTER } from '@/js/constants/form';
import { API } from '@/js/constants/api';

export default {
    ROUTE,
    ITEMS: USER_REGISTER.ITEMS,

    data() {
        return {
            isLoading: false,
            isVisible: false,
            formData: {
                name: '',
                email: '',
                password1: '',
                password2: '',
                permanentAddressCity: '',
            },
        };
    },

    watch: {
        'formData.password2': function formDataConfirmPassword() {
            this.$refs.password.validate();
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.send();
            }
        },
        togglePasswordVisibility() {
            this.isVisible = !this.isVisible;
        },
        send() {
            if (this.isLoading) return;
            this.isLoading = true;
            this.nullResponseMessages();

            axios({
                url: this.isLoggedSynthetic ? API.CONVERT_SU : API.REGISTER,
                method: this.isLoggedSynthetic ? 'put' : 'post',
                data: this.formData,
            })
                .then(() => {
                    this.$store.dispatch('doAlert', {
                        type: 'success',
                        text: this.$t('registration.success'),
                        multi: true,
                        timeout: 0,
                    });
                    this.$router.push({ name: ROUTE.LOGIN });
                })
                .catch((error) => {
                    this.emitErrorState(error);
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
        nullResponseMessages() {
            this.emitErrorState(null);
        },
        validatorConfirmPassword(value) {
            return (value === this.formData.password2) || this.$t('form.password.matchValidation', { icon: ERROR_ICON });
        },
    },
};
</script>

<style lang="less">
.v-messages .error-message__icon {
    display: none;
}

.error--text {
    .error-message__icon {
        display: inline-flex;
    }
}

.form-checkbox__required-icon {
    margin-left: 5px;
}
</style>
