<template>
    <div class="alert">
        <v-alert
            dense
            :type="type"
            :prominent="multi"
            :dismissible="isDismissible"
        >
            <template #default>
                {{ text }}
            </template>

            <template #close>
                <v-icon
                    v-if="isDismissible"
                    @click.prevent="dismissAlert"
                >
                    mdi-close
                </v-icon>
            </template>
        </v-alert>
    </div>
</template>

<script>
import { ALERT_TYPES } from '@/js/constants/alert';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        text: {
            type: [String],
            default: null,
        },
        type: {
            type: String,
            default: ALERT_TYPES.INFO,
        },
        multi: {
            type: String,
            default: null,
        },
        html: {
            type: String,
            default: null,
        },
        timeout: {
            type: [Number, String],
            default: 3000,
        },
        storeCallback: {
            type: String,
            default: null,
        },
    },
    computed: {
        isDismissible() {
            return +this.timeout === 0;
        },
    },
    mounted() {
        if (this.timeout) {
            setTimeout(() => {
                this.dismissAlert();
            }, this.timeout);
        }
    },
    methods: {
        dismissAlert() {
            this.$store.commit('removeAlert', this.id);
        },
    },
};
</script>

<style lang="less">
@import './src/less/variables';

.alert {
    padding: 0 20px;

    &:first-child {
        padding-top: 20px;
    }

    &:last-child {
        padding-bottom: 20px;
    }

    .v-icon {
        @media (max-width: @break-t-max) {
            display: none;
        }
    }
}
</style>
